<template>
  <button class="button-counter">
    <ButtonCTA :variant="variant" :content="content" />

    <span v-if="count > 0" class="count">{{ count }}</span>
  </button>
</template>

<script lang="ts" setup>
import type { AtomsIconText } from '../../../models/Atoms';

const props = withDefaults(
  defineProps<{
    content: AtomsIconText & { count: number };
    variant: 'primary' | 'secondary';
  }>(),
  {
    count: 1,
    variant: 'primary',
  }
);

const count = computed(() => props.content.count);
</script>

<style src="./Counter.scss" scoped lang="scss"></style>
